.App {
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: #61dafb;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}.App {
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: #61dafb;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}